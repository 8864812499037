import { template as template_5caf492e0c5f446793251a969f59c08c } from "@ember/template-compiler";
const FKLabel = template_5caf492e0c5f446793251a969f59c08c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
