import { template as template_412280cb9e9c48ee9ebc026de306a3ed } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_412280cb9e9c48ee9ebc026de306a3ed(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
